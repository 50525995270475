import { graphql } from "gatsby";
import * as React from "react";

import {
  Layout,
  HelmetSeo,
  Hero,
  Section,
  Cols,
  HorizontalContainer,
  Col,
  Button,
  SectionQuotes,
  FormattedRichText,
} from "../components/legacy";
import ARROW_RIGHT from "../../img/pages/logistics-document-processing/arrow_right.svg";
import ARROWS_CYCLE from "../../img/pages/logistics-document-processing/arrows_cycle.svg";
import AUTOMATIC_STEP_2 from "../../img/pages/logistics-document-processing/automatic_step_2.svg";
import AUTOMATIC_STEP_3 from "../../img/pages/logistics-document-processing/automatic_step_3.svg";
import AUTOMATIC_STEP_4 from "../../img/pages/logistics-document-processing/automatic_step_4.svg";
import CIRCLE_CYAN from "../../img/pages/logistics-document-processing/circle_cyan.svg";
import CIRCLE_ORANGE from "../../img/pages/logistics-document-processing/circle_orange.svg";
import SECTION_BACKGROUND_DARK_1 from "../../img/ui/patterns/section_background_dark_1.svg";
import HERO_BACKGROUND_1 from "../../img/ui/patterns/hero_background_1.svg";
import MANUAL_STEP_1 from "../../img/pages/logistics-document-processing/manual_step_1.svg";
import MANUAL_STEP_2 from "../../img/pages/logistics-document-processing/manual_step_2.svg";
import MANUAL_STEP_3 from "../../img/pages/logistics-document-processing/manual_step_3.svg";
import MANUAL_STEP_4 from "../../img/pages/logistics-document-processing/manual_step_4.svg";
import MANUAL_STEP_5 from "../../img/pages/logistics-document-processing/manual_step_5.svg";
import { unwrapFirst } from "../util";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { Color } from "../constants/legacy";

type Props = {
  data: GatsbyTypes.LogisticsDocumentProcessingPageQuery;
};

export const query = graphql`
  query LogisticsDocumentProcessingPage {
    prismicDocumentProcessingPage {
      data {
        hero_heading

        intro_heading
        intro_content {
          raw
        }
        intro_stat

        manual_heading
        manual_content {
          raw
        }

        manual_steps {
          manual_step_caption
        }

        automation_intro_heading
        automation_intro_content {
          raw
        }
        automation_intro_stat

        automation_what_heading
        automation_what_content {
          raw
        }

        automation_how_heading
        automation_how_content {
          raw
        }

        automation_how_steps {
          automation_how_step_title
          automation_how_step_description
        }

        automation_benefits_heading
        automation_benefits_description {
          raw
        }

        automation_benefits {
          automation_benefit_title
          automation_benefit_description
        }

        automation_comparisons {
          automation_comparison_title
          automation_comparison_content {
            raw
          }
        }

        choice_heading
        choice_content {
          raw
        }
        choice_content_button_label
        choice_content_button_link

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }

    prismicHomePage {
      data {
        ...SectionQuotesLegacy
      }
    }
  }
`;

export default function LogisticsDocumentProcessingPage({ data }: Props) {
  const page = data.prismicDocumentProcessingPage?.data;
  const homePage = data.prismicHomePage?.data;
  if (!page || !homePage) throw new Error("page/homePage missing");

  return (
    <Layout footerBackColor={Color.darkBlue}>
      <HelmetSeo {...page} />

      <Hero
        title={page.hero_heading || ""}
        customBackground={<StyledHeroBg src={HERO_BACKGROUND_1} />}
      />

      <StyledSectionGrey>
        <HorizontalContainer>
          <Cols>
            <Col flex={5}>
              <StyledCenterVertical>
                <div>
                  <h2>{page.intro_heading}</h2>
                  <FormattedRichText render={page.intro_content?.raw} />
                </div>
              </StyledCenterVertical>
            </Col>
            <Col flex={1} />
            <Col flex={5}>
              <StyledStatWrap>
                <StyledStat style={{ backgroundImage: `url(${CIRCLE_CYAN})` }}>
                  <span>{page.intro_stat}</span>
                </StyledStat>
              </StyledStatWrap>
            </Col>
          </Cols>
        </HorizontalContainer>
      </StyledSectionGrey>

      <Section>
        <HorizontalContainer>
          <h2>{page.manual_heading}</h2>
          <StyledColumns>
            <FormattedRichText render={page.manual_content?.raw} />
          </StyledColumns>

          <StyledProcessHeading>Manual Process</StyledProcessHeading>
          <StyledProcess>
            {(page.manual_steps || []).map(
              (step, i) =>
                step && (
                  <React.Fragment key={i}>
                    <StyledProcessItem>
                      <div className="image">
                        <img
                          src={
                            [
                              MANUAL_STEP_1,
                              MANUAL_STEP_2,
                              MANUAL_STEP_3,
                              MANUAL_STEP_4,
                              MANUAL_STEP_5,
                            ][i]
                          }
                        />
                      </div>
                      <div className="text">
                        <strong>{step.manual_step_caption}</strong>
                      </div>
                    </StyledProcessItem>

                    {i < 4 && (
                      <StyledProcessBetween>
                        <img src={i === 3 ? ARROWS_CYCLE : ARROW_RIGHT} />
                      </StyledProcessBetween>
                    )}
                  </React.Fragment>
                )
            )}
          </StyledProcess>
        </HorizontalContainer>
      </Section>

      <StyledSectionGrey>
        <HorizontalContainer>
          <Cols reverse>
            <Col flex={5}>
              <StyledCenterVertical>
                <div>
                  <h2>{page.automation_intro_heading}</h2>
                  <FormattedRichText
                    render={page.automation_intro_content?.raw}
                  />
                </div>
              </StyledCenterVertical>
            </Col>
            <Col flex={1} />
            <Col flex={5}>
              <StyledStatWrap>
                <StyledStat
                  style={{ backgroundImage: `url(${CIRCLE_ORANGE})` }}
                >
                  <span>{page.automation_intro_stat}</span>
                </StyledStat>
              </StyledStatWrap>
            </Col>
          </Cols>
        </HorizontalContainer>
      </StyledSectionGrey>

      <StyledSectionDark>
        <HorizontalContainer>
          <Cols>
            <Col>
              <h2>{page.automation_what_heading}</h2>
              <FormattedRichText
                onDark
                render={page.automation_what_content?.raw}
              />
            </Col>
            <Col />
          </Cols>
        </HorizontalContainer>
      </StyledSectionDark>

      <Section>
        <HorizontalContainer>
          <StyledHowWrap>
            <h2>{page.automation_how_heading}</h2>
            <FormattedRichText render={page.automation_how_content?.raw} />
          </StyledHowWrap>
          <StyledProcessHeading>AI OCR Process</StyledProcessHeading>
          <StyledProcess>
            {(page.automation_how_steps || []).map(
              (step, i) =>
                step && (
                  <React.Fragment key={i}>
                    <StyledProcessItem>
                      <div className="image">
                        <img
                          src={
                            [
                              MANUAL_STEP_1,
                              AUTOMATIC_STEP_2,
                              AUTOMATIC_STEP_3,
                              AUTOMATIC_STEP_4,
                            ][i]
                          }
                        />
                      </div>
                      <div className="text">
                        <strong>{step.automation_how_step_title}</strong>
                        <span>{step.automation_how_step_description}</span>
                      </div>
                    </StyledProcessItem>

                    {i < 3 && (
                      <StyledProcessBetween>
                        <img src={ARROW_RIGHT} />
                      </StyledProcessBetween>
                    )}
                  </React.Fragment>
                )
            )}
          </StyledProcess>
        </HorizontalContainer>
      </Section>

      <StyledSectionDark>
        <HorizontalContainer>
          <Cols>
            <Col>
              <h2>{page.automation_benefits_heading}</h2>
              <FormattedRichText
                onDark
                render={page.automation_benefits_description?.raw}
              />
            </Col>
            <Col />
          </Cols>
          <StyledBenefits>
            {(page.automation_benefits || []).map(
              (benefit) =>
                benefit && (
                  <div>
                    <h3>{benefit.automation_benefit_title}</h3>
                    <p>{benefit.automation_benefit_description}</p>
                  </div>
                )
            )}
          </StyledBenefits>
        </HorizontalContainer>
      </StyledSectionDark>

      <Section>
        <HorizontalContainer>
          {page.automation_comparisons?.map(
            (comparison, i) =>
              comparison && (
                <StyledComparison key={i}>
                  <h2>{comparison.automation_comparison_title}</h2>
                  <FormattedRichText
                    render={comparison.automation_comparison_content?.raw}
                  />
                </StyledComparison>
              )
          )}
        </HorizontalContainer>
      </Section>

      <StyledSectionDark>
        <HorizontalContainer>
          <Cols>
            <Col>
              <h2>{page.choice_heading}</h2>
              <FormattedRichText onDark render={page.choice_content?.raw} />
              <Button
                color="cyan"
                label={page.choice_content_button_label || ""}
                href={page.choice_content_button_link}
              />
            </Col>
            <Col />
          </Cols>
        </HorizontalContainer>
      </StyledSectionDark>

      <SectionQuotes home={homePage} />
    </Layout>
  );
}

const StyledHeroBg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const StyledSectionGrey = styled(Section)`
  background-color: #f6f8fa;
`;

const StyledCenterVertical = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledStatWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledStat = styled.div`
  width: 410px;
  height: 410px;
  border-radius: 205px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111;
  padding: 30px;
  box-sizing: border-box;

  span {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    line-height: 1.25;
  }

  @media (max-width: 960px) {
    width: 270px;
    height: 270px;

    span {
      font-size: 28px;
    }
  }
`;

const StyledColumns = styled.div`
  columns: 2;

  @media (max-width: 900px) {
    columns: 1;
  }
`;

const StyledProcessHeading = styled.div`
  margin-top: 40px;
  display: inline-block;
  margin-left: 40px;
  background-color: #f6f8fa;
  padding: 15px 40px 5px;
  font-size: 24px;
`;

const StyledProcess = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #f6f8fa;
  padding: 40px;
  position: relative;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const StyledProcessItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  line-height: 1.4;

  .image {
    width: 140px;
    height: 140px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 15px;
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: pre-line;
  }

  strong {
  }

  span {
    margin-top: 5px;
  }

  @media (max-width: 900px) {
    flex-direction: row;
    text-align: left;
    width: 100%;

    .image {
      margin-bottom: 0;
    }

    .text {
      margin-left: 20px;
      flex: 1;
      align-items: flex-start;
      max-width: 300px;
    }
  }
`;

const StyledProcessBetween = styled.div`
  width: 42px;
  height: 93px;
  margin: 26px -21px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    /* height: 42px;
    width: 93px;
    margin: 0 0 0 61px; */
    margin: -28px 0;
    margin-left: 50px;

    img {
      transform: rotate(90deg);
    }
  }
`;

const StyledSectionDark = styled(Section)`
  background-color: ${Color.blue};
  color: #fff;
  background-image: url(${SECTION_BACKGROUND_DARK_1});
  background-size: cover;

  h2,
  h3 {
    color: ${Color.cyan};
  }
`;

const StyledHowWrap = styled.div`
  max-width: 640px;
  margin: 0 auto;

  h3 {
    font-size: 15px;
    margin: 15px 0 5px;
    color: ${Color.orange};
  }
`;

const StyledBenefits = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 15px;
  margin-top: 40px;

  > div {
    width: 50%;
    box-sizing: border-box;
    padding-right: 80px;

    h3 {
      font-size: 15px;
      margin-bottom: 5px;
    }

    @media (max-width: 900px) {
      width: 100%;
      padding-right: 0;
    }
  }
`;

const StyledComparison = styled.div`
  background-color: #f6f8fa;
  padding: 100px;
  max-width: 740px;
  margin: 0 auto 30px;
`;
